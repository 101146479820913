.S3 {
    text-align: center;
    display: block;
  }
  
  .S3-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .S3-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .S3-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .S3-link {
    color: #61dafb;
  }
  
  @keyframes S3-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .s3header {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    color: floralwhite;
    background-color: #282c34;
  }
  .s3Form {
    
    display:inline-block;
    flex-direction:row;

  }
  .s3form > *{
    margin: 10px 0;
    padding: 0px;
  }
  .s3figure{
    width: 600px;
    display: flex;
    margin: auto;
    flex-direction: column;
  }
  .s3img{
    width: 100%;
  }
  .postStuff{
    display: flex;
    max-width: 200px;

  }
  .S3Input{
    width: 100px;
  }
  