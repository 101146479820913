.wholePage{
    background-color:rgb(10,34,14);
    background-color: black;
    min-height: 100vh;
}
.header{
    display: block;
    text-align: center;
    max-width: 100vw;
    background-color: black;
}
.bar{
    border-color: rgb(82, 179, 187);
    border-style: solid;
    border-top-width: 5px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    width: 100vw;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: right;
}

.headerPhoto{
    
    text-align: center;
    max-width: 60vw;
    padding: 10px;
}
.notesandeventsLayout{
    display: flex;
    flex-direction: row;
    
}