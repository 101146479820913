h1{
    color: white;
    font-size: 4vw;
}
.regPhoto{
    max-width: 30vw;
    max-height: 30vw;
}
.signUnContainer{
    display: flex;
    flex-direction: row;
    margin: 30px;
    padding-top: 20vh;
    align-content: center;
    text-align: center;
    line-height: 50px;
    padding-left: 7%;
}
.innerContainer{
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    padding-top: 8%;
}
form{
    display: flex;
    flex-direction: column;
}
input{
    text-align: center;
}