.airbnbMain{
    color: aliceblue;
    font-size: xx-large;
}
.barAirbnb{
    border-color: rgb(82, 179, 187);
    border-style: solid;
    border-top-width: 5px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    width: 90vw;
    padding-bottom: 5px;
    padding-top: 5px;

    margin: auto;
}
.airbnbText{
    padding-left: 5vw;
}
.airbnbBasicInfo{
    margin: 2vw;
    display: flex;
    flex-direction: row;
    
}
.airbnbPhoto{
width: 30vw;
margin: 10px;
}
.innerTextA{
    color: aliceblue;
    margin: 10px;
    
    font-size: 3vw;
}