@font-face { font-family: Chalk; src: url('../components/fonts/chalk.ttf'); } 
.planner{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;

}
.displayContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: mediumaquamarine;
    width: 25vw;
    
    margin: 10px;
}
.displayComments{
    color: azure;
    flex-basis: 70%;
}
.displayLikes{
    color: azure;
    flex-basis: 20%;
}
.displayDeleteButton{
    flex-basis: 10%;
}
.signIn{
    text-align: center;
    padding-bottom: 20vw;
    font-size: x-large;
}
input{
    flex: auto;
    flex-direction: column;
    margin: 5px;
}
.notesBoard{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-color: rgb(113, 48, 167);
    border: none;
    margin: 20px;
    max-width: 30vw;
    padding-left: 10px;
    margin-left: 5px;
    min-width: 20vw;
    
}
.displayMessageNotes{
    font-family: Chalk;
    font-weight: bold;
    padding-bottom: 3px;
    color: rgb(199, 229, 232);
    font-size: 2vw;
    flex-basis: 90%;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 1.8px;
    border-color: rgb(113, 48, 167);
}
.noteSetUp{
    display: flex;
    flex-direction: row;
}
.checkNote{
    flex-basis: 10%;
}
.displayTitleNotesContainer{
    display: flex;
    flex-direction: row;
}
.displayTitleNotes{
    font-size:4vw;
    font-family: Chalk;
    font-weight: bold;
    color: rgb(221, 249, 251);
    padding: 5px;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 3px;
    border-color: rgb(113, 48, 167);
    flex-basis: 90%;
}
.displayEventTimeNotes{
    font-size:larger;
    font-family: Chalk;
    font-weight: bold;
    color: rgb(148, 208, 213);
    padding: 5px;
}
.eraseNote{
    font-size: larger;
    font-family: Chalk;
    padding: 5px;
    background-color: transparent;
    color: white;
    border: none;
}
.addNote{
    font-size: 3vw;
    font-family:sans-serif;

    background-color: transparent;
    color: white;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 3px;
    border-color: rgb(113, 48, 167);
}
.modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    width: 85vw;
    background-color: rgb(29 70 36);
    border: 10px;
    border-style: solid;
    border-color: rgb(150, 127, 99);
}
.modalContent{
    justify-content: center;
    display:flex;
    flex-direction: column;
}
.submit{
    font-size: larger;
    font-family: Chalk;
    padding: 5px;
    background-color: transparent;
    color: white;
    border: none;
}
@media (min-width: 320px) and (max-width: 800px) {
    .displayMessageNotes{
        font-size: 3vw;
    }
    .notesBoard{
        max-width: 50vw;
    }

}
